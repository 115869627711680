import React from "react"
import { Link } from 'gatsby'
import footerStyles from '../styles/footer.module.scss'
import { Modal, Button } from 'react-bootstrap'

const Footer = () => {

    const [isOpen, setIsOpen] = React.useState(false);
    
    const showModal = () => {
        setIsOpen(true);
        //console.log('Hola')
    };
    
    const hideModal = () => {
        setIsOpen(false);
    };

    return(
        <div>
            <footer className={footerStyles.footer}>
                <nav>
                    <ul className={footerStyles.navList}>
                        <li>
                            <Link className={footerStyles.navItem} to="/contact">Contacto</Link>
                        </li>
                        <li>
                            <button className={footerStyles.navItemButton} onClick={showModal}>Aviso de Privacidad</button>
                        </li>
                    </ul>
                    <p>Copyright 2020. Todos los derechos reservados por MRA CMEX, SA. DE C.V. / CONTROL INTEGRAL</p>
                </nav>
            </footer>
            <Modal size="lg" show={isOpen} onHide={hideModal}>
                <Modal.Header>
                    <Modal.Title>Aviso de Privacidad</Modal.Title>
                </Modal.Header>
                <Modal.Body className={footerStyles.texto}>
                    En términos de lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento (conjuntamente “LFPDPPP”), se identifica como responsable del tratamiento de los datos personales recabados a los titulares de los mismos, en lo individual, a MRA CIMEX, S.A. de C.V., con domicilio en Av. Ignacio Zaragoza 279 Int 207, Col del Prado, Santiago de Querétaro, QRO 76000, con número telefónico (442) 193 - 5289 y en corro electrónico contacto@control-integral.com, (denominada en lo sucesivo el “Responsable”), ponen a su disposición el presente aviso de privacidad, en virtud de los datos personales que se pretenden recabar de Usted, en su calidad de Cliente, Empleado, Accionista, Candidato o Proveedor, según corresponda, como titular de los mismos (en lo sucesivo el “Titular”).<br/><br/>
                    <b>Datos Personales</b><br/><br/>
                    Para las finalidades del estudio socioeconómico es necesario para continuar con el proceso de selección de personal, solicitud de crédito, solicitud de apoyo financiero o solicitud de póliza de seguro, según sea el caso; el Titular proporcionará datos personales (en lo sucesivo “Datos Personales”), mismos que podrán ser proporcionados directamente, vía nuestra web, servicios en línea y a través de empresas que nos contratan nuestros servicios, y/o cuando obtenemos información a través de otras fuentes que están permitidas por la ley (directorios telefónicos o laborales, etc.), por tal razón le garantizamos que los datos personales proporcionados por usted serán tratados bajo las mas estrictas medidas de seguridad, garantizando siempre la confidencialidad de los mismos.<br/><br/>
                    Los datos personales que le soltaremos serán: Datos generales como nombre completo, fecha de nacimiento, registro federal de contribuyentes, número de seguridad social, nacionalidad y datos migratorios, sexo, correo electrónico, documentos probatorios en original de nacimiento, domicilio, estado civil, número de teléfono, ocupación, salario, datos de identificación, CURP, académicos, certificados de educación o constancia de estudios, patrimoniales y de contacto, referencias laborales, referencias patrimoniales, referencias comerciales, actas de nacimiento de hijos (en caso aplicable), así como información relacionada con dependientes económicos, datos de su familia, actual, de origen, tanto de madre como de padre, de trayectoria escolar y laboral.<br/><br/>
                    También se podrán recabar y tratar los siguientes datos personales sensibles, según la LFPDPPP: Creencias religiosas, políticas y afiliación sindical, estatus de vivienda, automóvil y otros activos o bienes muebles e inmuebles, así como: biométricos (huellas dactilares, rasgos faciales, de iris, patrón de voz, entre otros), información relativa al estado de salud, enfermedad padecidas o que padece, tratándose de empleo, seguros de vida o gastos médicos, información financiera sobre sus transacciones, historial de pagos, actividad, uso y propósito de los productos y servicios financieros, incluyendo sin limitar créditos, cuentas, inversiones, certificados de depósito, depósitos a la vista o a plazo, así como el origen y destino de los recursos, ingresos o activos e información proporcionada por ti sobre familiares como cónyuge, hijos, padres, beneficiarios, intermediarios y cualquier otro tercero cuya información sea requerida en cumplimiento a las disposiciones legales vigentes y aplicables.<br/><br/>
                    También le informamos que una vez concluida la entrevista y verificación de datos personales proporcionados, podrá o no capturarse, dependiendo el proceso que de origen al presente estudio socioeconómico, su imagen en una o varias fotografías, tanto en el interior y exterior de su domicilio, como parte del informe a entregar a la empresa solicitante del estudio socioeconómico, visita domiciliaria o auditoria de datos.<br/><br/>
                    <b>Finalidades a los Datos personales</b><br/><br/>
                    El Responsable, para la consecución de su objeto social, así como para el tratamiento a los datos personales que nos fueron transferidos por la empresa en la cual usted inició un proceso de selección de personal, solicitud de crédito, solicitud de apoyo financiero o solicitud de póliza de seguro, según corresponda, le es necesario obtener datos personales del Titular o Candidato, ya sean personas físicas o personas morales y de éstas últimas requiere los Datos Personales de sus representantes o accionistas a efecto de llevar a cabo la suscripción de los contratos respectivos.<br/><br/>
                    Los Datos Personales serán tratados y/o utilizados por el Responsable y/o sus empresas filiales y/o subsidiarias y/o aquellos terceros que, por la naturaleza de sus trabajos o funciones tengan la necesidad de tratar y/o utilizar los mismos; con el propósito de cumplir aquellas obligaciones que se derivan o se lleguen a derivar de la relación jurídica entre el Titular y el Responsable.<br/><br/>
                    <b>Formas de Limitación del uso o divulgación de Datos personales</b><br/><br/>
                    El uso de los datos personales por parte del Responsable, solo se circunscribe para los fines de estudios socioeconómicos de clientes contratados y que han recibido previamente el consentimiento del Titular en la participación del o los procesos del estudio socioeconómico, visita domiciliaria o auditoria de datos.<br/><br/>
                    <b>Medios para ejercer los derechos ARCO (Acceso, Rectificación, Cancelación u Oposición)</b><br/><br/>
                    El Titular tiene derecho a acceder a sus datos personales que poseemos, sus detalles, tratamiento, rectificación, oponerse a ser usados a los fines específicos o instruirnos para cancelarlos en caso de considerarlos excesivos a su parecer.<br/><br/>
                    <b>Transferencia de datos Personales</b><br/><br/>
                    Los datos personales del Titular, pueden ser transferidos y tratados dentro de nuestro país, por empresas del mismo grupo o personas que tengan participación accionaria dentro de las mismas empresas, de esta denominación social y comercial. En este sentido, su información puede ser compartida solo para las finalidades establecidas en este Aviso de privacidad. El Responsable se compromete a que los mismos serán tratados bajo medidas de seguridad, siempre garantizando su confidencialidad.<br/><br/>
                    <b>Si el Titular, considera que su derecho de protección de datos personales ha sido lesionada o violada, por nuestra empresa o sus empleados, a las disposiciones previstas en la LFPDPPP, podrá interponer la queja o denuncia correspondiente ante el IFAI, para mayor información visite <a href="https://www.ifai.org.mx">www.ifai.org.mx</a>. Lo no establecido en el presente Aviso, se aplicará la ley respectiva.</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    ) 
}
export default Footer;
