import React from "react"
import { Link } from 'gatsby';
import headerStyles from '../styles/header.module.scss'
import logo from '../images/Logo_CI.png';

const Header = () => {
    return(
        <header className={headerStyles.header}>
            <h3 className={headerStyles.title}>Control Integral</h3>
            <Link to="/"><img className={headerStyles.image} src={logo} alt="logo" /></Link>
            <nav>
                <ul className={headerStyles.navList}>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">Nosotros</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}  to="/product">Productos</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contacto</Link>
                    </li>
                </ul>
            </nav>
        </header>
    ) 
}
export default Header;
