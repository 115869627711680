import React from 'react'
import Footer from '../components/footer';
import Header from '../components/header';
import layoutStyles from '../styles/layout.module.scss'
import ScrollUpButton from "react-scroll-up-button";

const Layout = (props) => {
	return (
		<div>
			<Header/>
      <div className={layoutStyles.container}>
      	<div className={layoutStyles.content}>
					{props.children}
        </div>
        <Footer/>
      </div>
			<ScrollUpButton style={{width: 40, height: 40}} />
		</div>
  )
}

export default Layout